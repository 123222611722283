import { createRouter, createWebHistory } from "vue-router";
import { useClientStore } from "@/stores/clientStore";
import { findParentRoute } from "@/utils/helpers";
import { datadogRum } from "@datadog/browser-rum";
import { setupI18n, createSpiels } from "@/utils/spiels";
import { ref, computed } from "vue";

const OnboardingPage = () => import("@/pages/Onboarding/OnboardingPage.vue");
const OnboardingWelcome = () =>
  import("@/pages/Onboarding/OnboardingWelcome.vue");
const TaxAdmin = () => import("@/pages/TaxAdmin/TaxAdmin.vue");
const TaxAdminHome = () => import("@/pages/TaxAdmin/TaxAdminHome.vue");
const TaxAdminArchived = () => import("@/pages/TaxAdmin/TaxAdminArchived.vue");
const TaxAdminPayments = () => import("@/pages/TaxAdmin/TaxAdminPayments.vue");
const TaxAdminLetterControl = () =>
  import("@/pages/TaxAdmin/TaxAdminLetterControl.vue");
const TaxAdminClientControl = () =>
  import("@/pages/TaxAdmin/TaxAdminClientControl.vue");
const OperationSettings = () => import("@/pages/Operation/Operation.vue");
const OperationBillingPlan = () =>
  import("@/pages/Operation/OperationBillingPlan.vue");
/*const OperationBillingDetails = () =>
  import("@/pages/Operation/OperationBillingDetails.vue");*/
const OperationIrd = () => import("@/pages/Operation/OperationIrd.vue");
const OperationXpm = () => import("@/pages/Operation/OperationXpm.vue");
const OperationStaff = () => import("@/pages/Operation/OperationStaff.vue");
const OperationPractice = () =>
  import("@/pages/Operation/OperationPractice.vue");
const OperationEmail = () => import("@/pages/Operation/OperationEmail.vue");
const Account = () => import("@/pages/Account/Account.vue");
const AccountHome = () => import("@/pages/Account/AccountHome.vue");
const AccountSecurity = () => import("@/pages/Account/AccountSecurity.vue");
const Login = () => import("@/pages/Login/Login.vue");
const NotFound = () => import("@/pages/NotFound/NotFound.vue");
const PluginTesting = () => import("@/pages/Testing/Testing.vue");
const TaxAdminLetterHistory = () =>
  import("@/pages/TaxAdmin/TaxAdminLetterHistory.vue");

const Admin = () => import("@/pages/Testing/Admin.vue");
const AdminBatch = () => import("@/pages/Testing/AdminBatch.vue");
const AdminPartner = () => import("@/pages/Testing/AdminPartner.vue");

const PreviewEmail = () => import("@/pages/PreviewPage/PreviewEmail.vue");
export const dynamicRouteNames = ref({});

const routes = [
  {
    path: "/welcome",
    component: OnboardingWelcome,
    name: "OnboardingWelcome",
    meta: {
      showInNav: false,
      hideHeader: true,
      hideAccount: true,
      hideNav: true,
      auth: true,
      hideTab: () => false,
    },
  },
  {
    path: "/preview/:msgid",
    component: PreviewEmail,
    name: "PreviewEmail",
    meta: {
      showInNav: false,
      hideHeader: true,
      hideAccount: false,
      hideNav: false,
      auth: true,
      hideTab: () => true,
    },
  },
  {
    path: "/onboarding",
    component: OnboardingPage,
    name: "OnboardingPage",
    meta: {
      showInNav: false,
      hideHeader: true,
      hideAccount: true,
      hideNav: true,
      auth: true,
      hideTab: () => false,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: {
      showInNav: false,
      hideHeader: true,
      hideAccount: true,
      hideNav: true,
      auth: false,
      hideTab: () => false,
    },
    component: () => import("@/pages/Login/ForgotPassword.vue"),
  },
  {
    path: "/reset-password/:confirm",
    name: "ResetPassword",
    meta: {
      showInNav: false,
      hideHeader: true,
      hideAccount: true,
      hideNav: true,
      auth: false,
      hideTab: () => false,
    },
    component: () => import("@/pages/Login/ResetPassword.vue"),
  },
  {
    path: "/activate-account/:confirm",
    name: "ActivateAccount",
    meta: {
      showInNav: false,
      hideHeader: true,
      hideAccount: true,
      hideNav: true,
      auth: false,
      hideTab: () => false,
    },
    component: () => import("@/pages/Login/ActivateAccount.vue"),
  },
  {
    path: "/",
    name: "Login Page",
    meta: {
      showInNav: false,
      hideHeader: true,
      hideAccount: true,
      hideNav: true,
      auth: false,
      hideTab: () => false,
    },
    component: Login,
  },
  {
    path: "/tax-admin",
    name: computed(() => dynamicRouteNames.value.taxAdmin || "Tax_Admin"),
    meta: { showInNav: true, auth: true, hideTab: () => false },
    component: TaxAdmin,
    children: [
      {
        path: "",
        name: "Tax admin home",
        meta: {
          group: "Tax Letters",
          alias: "Inbox",
          icon: "MarkUnreadMailboxIcon",
        },
        component: TaxAdminHome,
      },
      {
        path: "archived",
        name: "Tax admin archived",
        meta: {
          group: "Tax Letters",
          alias: "Archived",
          icon: "PackageDownIcon",
        },
        component: TaxAdminArchived,
      },
      {
        path: "letter-control",
        name: "Tax admin letter control",
        meta: {
          group: "Tax Letters",
          alias: "Letter control",
          icon: "CogIcon",
        },
        component: TaxAdminLetterControl,
      },
      {
        path: "client-control",
        name: "Tax admin client control",
        meta: {
          group: "Clients",
          alias: "Clients",
          icon: "FaceManIcon",
        },
        component: TaxAdminClientControl,
      },
      {
        path: "payments",
        name: "Tax admin payments",
        meta: {
          group: "Payments",
          alias: "Payments due",
          icon: "PiggyBankIcon",
          hideTab: () => false,
        },
        component: TaxAdminPayments,
      },
      {
        path: "letter-history",
        name: "Tax admin letter history",
        meta: {
          group: "History",
          alias: "Event log",
          icon: "HistoryIcon",
        },
        component: TaxAdminLetterHistory,
      },
    ],
  },
  {
    path: "/operation-settings",
    name: computed(
      () => dynamicRouteNames.value.opSettings || "Operation_Settings"
    ),
    meta: {
      showInNav: true,
      auth: true,
      hideTab: (role) =>
        !role.some((r) =>
          ["group:admin", "group:sysadmin", "group:owner"].includes(r)
        ),
    },
    component: OperationSettings,
    children: [
      {
        path: "",
        name: "Operation ird",
        displayName: computed(
          () => dynamicRouteNames.value.taxOffice || "Tax_Office"
        ),
        meta: {
          group: "Connections",
          alias: computed(
            () => dynamicRouteNames.value.taxOffice || "Tax_Office"
          ),
          icon: "BankIcon",
        },
        component: OperationIrd,
      },
      {
        path: "xpm",
        name: "Operation xpm",
        meta: {
          group: "Connections",
          alias: "XPM",
          icon: "BriefcaseIcon",
        },
        component: OperationXpm,
      },
      {
        path: "plan",
        name: "Billing plan",
        meta: {
          group: "Billing",
          alias: "Plan",
          icon: "CreationIcon",
        },
        component: OperationBillingPlan,
      } /*
      {
        path: "billing-details",
        name: "Billing details",
        meta: {
          group: "Billing",
          alias: "Billing details",
          icon: "CashMultipleIcon",
        },
        component: OperationBillingDetails,
      },*/,
      {
        path: "practice",
        name: "Operation practice",
        meta: {
          group: "Practice",
          alias: "Manage practice",
          icon: "BriefcaseVariantIcon",
        },
        component: OperationPractice,
      },
      {
        path: "staff",
        name: "Operation staff",
        meta: {
          group: "Practice",
          alias: "Practice users",
          icon: "AccountMultipleIcon",
        },
        component: OperationStaff,
      },
      {
        path: "email",
        name: "Operation email",
        meta: {
          group: "Practice",
          alias: "Email templates",
          icon: "EmailIcon",
        },
        component: OperationEmail,
      },
    ],
  },
  {
    path: "/account",
    name: "My account",
    meta: { showInNav: false, auth: true, hideTab: () => false },
    component: Account,
    children: [
      {
        path: "",
        name: computed(
          () =>
            `Account ${dynamicRouteNames.value.profile}` || "Account_Profile"
        ),
        displayName: computed(
          () => dynamicRouteNames.value.profile || "Profile"
        ),
        meta: {
          group: "Account Settings",
          alias: computed(() => dynamicRouteNames.value.profile || "Profile"),
          icon: "AccountIcon",
        },
        component: AccountHome,
      },
      {
        path: "security",
        name: computed(
          () =>
            `Account ${dynamicRouteNames.value.passwordSecurity}` ||
            "Account_Password&Security"
        ),
        displayName: computed(
          () => dynamicRouteNames.value.passwordSecurity || "Password&Security"
        ),
        meta: {
          group: "Account Settings",
          alias: computed(
            () =>
              dynamicRouteNames.value.passwordSecurity || "Password&Security"
          ),
          icon: "FingerprintIcon",
        },
        component: AccountSecurity,
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    meta: {
      showInNav: true,
      auth: true,
      hideTab: (role) => !role.some((r) => ["group:sysadmin"].includes(r)),
    },
    component: Admin,
    children: [
      {
        path: "",
        name: "Partner",
        meta: { group: "Support", alias: "Partners" },
        component: AdminPartner,
      },
      {
        path: "Batch",
        name: "Batch Jobs",
        meta: { group: "Support", alias: "Batch Jobs" },
        component: AdminBatch,
      },
      {
        path: "Testing",
        name: "Plugin Testing",
        meta: { group: "Testing", alias: "Testing" },
        component: PluginTesting,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404 Not Found",
    component: NotFound,
    meta: { showInNav: false, hideHeader: true, hideNav: true, auth: false },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const updateRouteNames = async () => {
  const store = useClientStore();
  if (store.isAuthenticated) {
    const locale = store.region === "AU" ? "en-AU" : "en-NZ";
    const i18n = await setupI18n(locale);
    const spiels = createSpiels(i18n);

    dynamicRouteNames.value = {
      taxAdmin: spiels?.APP.NAME,
      opSettings: spiels?.APP.OP_SETTINGS,
      taxOffice: spiels?.APP.TAX_OFFICE,
      profile: spiels?.APP.PAGE.PROFILE,
      passwordSecurity: spiels?.APP.PAGE.PASSWORD_SECURITY,
    };
  }
};

router.beforeEach(async (to, from, next) => {
  await updateRouteNames();
  const store = useClientStore();

  if (store.isAuthenticated) {
    datadogRum.setUser({
      name: `${store.getItem("firstName")} ${store.getItem("lastName")}`,
      email: store.getItem("email"),
      plan: store.getItem("plan"),
      partnerId: store.getItem("partnerId"),
      role: store.getItem("role"),
      userEnv:
        process.env.VUE_APP_BASE_API_URL === "https://api.pluginaccountant.com"
          ? "prod"
          : "staging/other",
    });
  }

  if (store.isAuthenticated && to.meta.auth) {
    const role = store.getItem("role");
    const isOwner = role.some((r) =>
      ["group:owner", "group:sysadmin"].includes(r)
    );

    const isStaff = !role.some((r) =>
      ["group:admin", "group:sysadmin", "group:owner"].includes(r)
    );
    if (["OnboardingPage", "OnboardingWelcome"].includes(to.name)) {
      if (!isOwner) {
        next("/operation-settings");
      }

      if (store.getItem("welcomeViewed")) {
        next("/operation-settings");
      }

      next();
    }

    if (isOwner) {
      if (!store.getItem("welcomeViewed")) {
        next("/welcome");
      }
    }

    if (to.path.startsWith("/sys/") || to.path.startsWith("/admin/")) {
      if (role.includes("group:sysadmin")) {
        next();
      } else {
        next("/tax-admin");
      }
    }

    if (findParentRoute(routes, to.name).path === "/operation-settings") {
      if (isStaff) {
        next("/tax-admin");
      }
    }

    const isAURegion = store.region === "AU";

    routes.forEach((route) => {
      if (route.path === "/tax-admin") {
        route.children.forEach((child) => {
          if (child.path === "payments") {
            child.meta.hideTab = () => isAURegion;
          }
        });
      }
    });

    next();
  } else if (store.isAuthenticated && !to.meta.auth) {
    next("/operation-settings");
  } else if (!store.isAuthenticated && !to.meta.auth) {
    next();
  } else if (!store.isAuthenticated && to.meta.auth) {
    next("/");
  } else next();
});

export default router;
