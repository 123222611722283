import { createI18n } from "vue-i18n";

export const createSpiels = (i18n) => {
  const spiels = {
    APP: {
      GREETING: i18n.global.t("hello"),
      NAME: "Tax Admin",
      EXTERNAL: "Tax Admin App",
      LEARN_MORE: "Learn more",
      OP_SETTINGS: "Operation Settings",
      LOGIN: "Log in",
      SIGN_IN_XERO: "Sign in with Xero",
      RESET_PASSWORD: {
        VALIDATION_FAILED: "Password reset validation failed",
      },
      FORGOT_PASSWORD: {
        CONFIRM: "Send password instructions",
        HEADER: "Forgot password",
        get SUBHEADER() {
          return `If you're not sure which email address you used with ${spiels.APP.NAME}, contact <a href="mailto:support@pluginaccountant.com">${spiels.SUPPORT.EMAIL}</a>.`;
        },
      },
      SET_PASSWORD: {
        CONFIRM: "Reset password",
        HEADER: "Reset password",
        SUBHEADER: "Your new password must be different from your current one.",
        MODAL: {
          TITLE: "New password saved",
          BODY: "You can log in with your new password.",
          CONFIRM: "OK, thanks",
        },
      },
      ACTIVATE_ACCOUNT: {
        CONFIRM: "Set password",
        HEADER: "Set your password",
        get SUBHEADER() {
          return `Hi {name}, ${spiels.APP.WELCOME}! Set up your password and let's start your onboarding.`;
        },
        MODAL: {
          TITLE: "New password saved",
          BODY: "You can log in with your new password.",
          CONFIRM: "OK, thanks",
        },
      },
      get WELCOME() {
        return `welcome to Plugin Accountant's Tax Admin App`;
      },
      TAX_OFFICE: i18n.global.t("taxOfficeName"),
      TAX_OFFICE_CLIENT: i18n.global.t("taxOfficeClient"),
      TAX_AUTH: i18n.global.t("taxAuth"),
      TAX_NUMBER: i18n.global.t("taxNumber"),
      TAX_NUMBERS: i18n.global.t("taxNumbers"),
      TAX_LETTER: i18n.global.t("taxLetter"),
      CURRENCY_CODE: i18n.global.t("currency_code"),
      TAX_CLIENT_LIST: i18n.global.t("taxClientList"),
      GRID_SEARCH_PLACEHOLDER: i18n.global.t("gridSearchPlaceholder"),
      PLAN: {
        get CENTRALISIED() {
          return `Centralised ${spiels.APP.TAX_OFFICE} communications`;
        },
        get TIER_ONE() {
          return `<span class="fw-bold">$0.25</span> per ${spiels.APP.TAX_OFFICE_CLIENT} up to
              2.5k`;
        },
        get TIER_TWO() {
          return `<span class="fw-bold">$0.20</span> per ${spiels.APP.TAX_OFFICE_CLIENT} between
              2.5k - 5k`;
        },
        get TIER_THREE() {
          return `<span class="fw-bold">$0.15</span> per ${spiels.APP.TAX_OFFICE_CLIENT} between
              5k - 7.5k`;
        },
        get TIER_FOUR() {
          return `<span class="fw-bold">$0.10</span> per ${spiels.APP.TAX_OFFICE_CLIENT} over
              7.5k`;
        },
      },
      WELCOME_TAX_ADMIN: "Welcome to Tax Admin",
      OPS_IRD: {
        AUTH_LIST: {
          get HEADER() {
            return `${spiels.APP.TAX_AUTH}`;
          },
          BODY: "Add IRD authorities from your practice. Each person with authority will need to log in to IRD page.",
        },
        CLIENT_LIST: {
          HEADER: "IRD Client List",
          TITLE: "IRD Client List ID",
          get BODY() {
            return `Add the ${spiels.APP.OPS_IRD.CLIENT_LIST.TITLE} you want to bring into ${spiels.APP.NAME}. ${spiels.APP.OPS_IRD.CLIENT_LIST.TITLE} might be the same as your practice IRD number.`;
          },
          STATE: {
            DELINKED: "Delinked",
            VERIFIED: "Verified",
            FAILED: "Failed",
          },
          get DELINKED_MESSAGE() {
            return `${spiels.APP.OPS_IRD.CLIENT_LIST.NO_AUTH_MESSAGE} &ndash; add the authority for this ID to receive new client data from this list.`;
          },
          NO_AUTH_MESSAGE: "No matching authority found",
        },
      },

      OPS_XPM: {
        REFRESH_XPM: {
          TITLE: "Refresh XPM data",
          BUTTON: "Refresh XPM data",
          DESCRIPTION: "Pull the latest XPM data into Plugin.",
          TOOLTIP:
            "Refreshing XPM data will override your current client and manager details in Tax Admin",
        },
        REFRESH_MODAL: {
          TITLE: "Refresh XPM data",
          TOOLTIP_TITLE: "What happens when I refresh XPM data?",
          TOOLTIPS: [
            "Refreshing XPM data will override your current client and manager details in Tax Admin.",
            "Outgoing emails and payment notifications will be paused until you enable them again.",
            "Make sure you’re happy with the refreshed clients and managers data, then resume outgoing letters and payment notifications",
          ],
          CONFIRMATION_MESSAGE: "Are you sure you want to refresh XPM data?",
          CANCEL: "Cancel",
          CONFIRM: "Yes, refresh",
        },
        CONNECTION_STATUS: {
          TITLE: "Connection status",
          DESCRIPTION: "Stay connected to XPM",
          get TOOLTIP() {
            return `Disconnecting will disable ability to manually match entities to ${spiels.APP.TAX_OFFICE}.`;
          },
        },
        NOTIFICATIONS: {
          TOOLTIP: "Tooltip:",
          ON: "On",
          OFF: "Off",
          DISABLED: "Disabled",
          ENABLE_OUTGOING_EMAILS: {
            TITLE: "Enable outgoing emails",
            SUBTITLE: "Start or resume outgoing emails",
            TOOLTIPS: [
              "Enabling will start or resume outgoing emails according to the letter control settings.",
              "Disabling will pause outgoing emails.",
            ],
          },
          ENABLE_PAYMENT_NOTIFICATIONS: {
            TITLE: "Enable payment notifications",
            SUBTITLE: "Start or resume payment notifications",
            TOOLTIPS: [
              "Enabling will start or resume outgoing payment notification emails.",
              "Disabling will pause outgoing payment notification emails.",
            ],
          },
          OFF_OUTGOING_EMAILS: {
            TITLE: "What happens when I switch off?",
            get CONTENT() {
              return `Automatic ${spiels.APP.TAX_OFFICE} letters will stop being sent to clients and managers immediately. You can still use the manual send function in the inbox.`;
            },
            CONFIRM: "Are you sure you want to switch off outgoing emails?",
            OFF: "Outgoing emails are now off",
          },
          PAYMENT_NOTIFICATION_ON: {
            TITLE: "What happens if I switch payment notifications on?",
            CONTENT:
              "Reminders will start being sent to clients, for upcoming or overdue payments based on the following timeframes:<br /><ul class='notifications-payment'><li>Overdue – up to 5 working days overdue from today</li><li>Upcoming – due in 2 working days from today</li></ul>",
            SUCCESS: {
              TITLE: "Payment notifications are on",
              CONTENT: "Payment notifications will now be sent to clients.",
            },
            CONFIRM:
              "Are you sure you want to switch on payment notifications?",
          },
          PAYMENT_NOTIFICATION_OFF: {
            TITLE: "What happens when I switch off?",
            CONTENT:
              "Automatic payment notifications will stop being sent to clients immediately. You can still use the manual send function in the payments due page.",
            CONFIRM:
              "Are you sure you want to switch off payment notifications?",
            SUCCESS: {
              TITLE: "Payment notifications are now off",
            },
          },
          TIP: {
            CONTENT:
              "If you want to send the notifications that were due before you switched the payment notifications on, use the manual send on <a class='text-success' href='/tax-admin/payments' target='_blank'>Payments due.</a>",
          },
          THINGS_TO_CHECK: {
            TITLE: "Things to check",
            LIST: [
              "Check <a href='/tax-admin/client-control' class='text-success' target='_blank'>client data</a> is correct.",
              "This will ensure that notifications are sent to the correct clients.",
              "For more details, see our in-depth <a class='text-success' href='https://knowledge.pluginaccountant.com/hc/en-nz/sections/10154734510351-Payments-Due' target='_blank'>help article.</a>",
            ],
          },
        },
      },
      PRODUCTS: {
        ESSENTIAL: {
          TITLE: "Essential",
          PRICE: "Free",
        },
        PRO: {
          TITLE: "Pro",
          PRICE: "0.25",
        },
      },
      get TERMSANDCONDITIONS() {
        return `By clicking Confirm my plan, I agree to <a class='text-success' href='${spiels.LINK.TERMS}'>Terms & Conditions.</a>`;
      },
      PAGE: {
        PASSWORD_SECURITY: "Password & security",
        PROFILE: "Profile",
      },
    },
    SUPPORT: {
      EMAIL: "support@pluginaccountant.com",
    },
    LINK: {
      PRO: "https://knowledge.pluginaccountant.com/hc/en-nz/categories/10162829209871-Tax-Admin-App-Pro",
      ESSENTIALS:
        "https://knowledge.pluginaccountant.com/hc/en-nz/categories/10150785896335-Tax-Admin-App-Essentials",
      HELP: "https://knowledge.pluginaccountant.com/hc/en-nz",
      PRICING: "https://www.pluginaccountant.com/pricing",
      IRD: "https://knowledge.pluginaccountant.com/hc/en-nz/articles/10150755543823-How-to-add-your-IRD-Authorities-singular-or-multiple-and-IRD-Client-List-s-to-the-App-and-verify-via-MyIR",
      XPM: "https://knowledge.pluginaccountant.com/hc/en-nz/articles/10162952553743-Linking-Tax-Admin-App-Pro-with-XPM",
      CLIENT_XPM:
        "https://knowledge.pluginaccountant.com/hc/en-nz/articles/10162956319375-Instructions-on-how-to-build-and-send-a-client-report-from-XPM",
      EMAIL:
        "https://knowledge.pluginaccountant.com/hc/en-nz/articles/10163127591695-Email-Templates",
      PRO_EMAIL:
        "https://knowledge.pluginaccountant.com/hc/en-nz/articles/10163127591695-Email-Templates",
      BILLING:
        "https://knowledge.pluginaccountant.com/hc/en-nz/articles/10150785144847-Billing",
      TERMS: "https://www.pluginaccountant.com/terms-and-conditions/",
    },
    BANNER: {
      TAX_ARCHIVED: {
        DEFAULT: i18n.global.t("archivedBanner"),
        TITLE: "Letter auto action not enabled",
        SEND_DISABLED:
          "Your letters are not automatically sending out yet. <a class='text-success' href='/operation-settings/xpm'>Enable auto actions</a> to start or resume letter auto action.",
      },
      TAX_HOME: {
        ESSENTIAL: i18n.global.t("inboxBanner"),
        PRO: "This is your tax letters inbox. All non-automated emails will be saved here. You won’t be able to send the letter to the client or manager if the data is missing.",
      },
      CLIENT_CONTROL: {
        ESSENTIALS:
          "Client email and manager data is imported from XPM. To add email or assign manager, update the respective field in XPM then refresh XPM data in Operation settings.",
        get PRO() {
          return `Upgrade to ${spiels.APP.NAME} Pro to add client emails, set up withheld letters for clients. ${spiels.BANNER.UPGRADE}`;
        },
      },
      PAYMENT_DUE: {
        COMING_SOON: "Coming soon",
        CONFIRM_PAYMENT:
          "Are you sure you want to send a payment notification email to this client?",
        EMAIL_SENT:
          "<h6><strong>Notification email sent</strong></h6><p>You can find the delivery status and history of notification emails in Event log tab.</p>",
        EMAIL_TEMPLATE:
          "Admin users can customise Payments Due notification templates in <a class='text-success' href='/operation-settings/email'>Email templates.</a>",
        ESSENTIALS:
          "Every unpaid tax bill from Payments database, in the past and upcoming.",
        TIPS: {
          ONE: "If you don’t need notifications for a payment, turn on the Withhold toggle for that payment.",
          TWO: "Payments won’t be retrieved from accounts we don’t have permission to access – see clients page for permissions.",
        },

        get PRO() {
          return `<a class='text-success' href='/operation-settings/plan'>Upgrade to Tax Admin Pro</a> to unlock this feature. <a href='https://www.pluginaccountant.com/pricing/' target='_blank' class='text-success'>View feature comparison</a>`;
        },
        NOTIFICATIONS: {
          ENABLED: {
            get BODY() {
              return `This is your ${spiels.APP.TAX_OFFICE} client payments due page. It shows every unpaid tax bill from ${spiels.APP.TAX_OFFICE} database in the past and upcoming, <strong>excluding payroll and provisional taxes</strong>.`;
            },
          },
          DISABLED: {
            TITLE: "Payment notifications not enabled",
            BODY: `Your payment notifications are not enabled yet. <a href='/operation-settings/xpm'>Enable</a> to start or resume outgoing payment notifications.`,
          },
        },
      },
      OPS: {
        get DEFAULT() {
          return `This is the operation settings page. You can set up the key functions for your practice here. Connect your ${spiels.APP.TAX_CLIENT_LIST} to start importing your client data.`;
        },
      },
      OPS_LETTER_HISTORY: {
        TITLE: "Available in Tax Admin Pro",
        get ESSENTIALS() {
          return `Upgrade to ${spiels.APP.NAME} Pro to view letter history. ${spiels.BANNER.UPGRADE}`;
        },
      },
      OPS_XPM: {
        PRO: "Refreshing XPM data will override your current client email addresses and assigned accountants in Tax Admin",
        get ESSENTIALS() {
          return `Upgrade to ${spiels.APP.NAME} Pro to import client emails and managers from XPM. ${spiels.BANNER.UPGRADE}`;
        },
        get UPDATE_CLIENT() {
          return `Update client email in XPM then refresh XPM data from ${spiels.APP.OP_SETTINGS}.`;
        },
        get UPDATE_MANAGER() {
          return `Update manager in XPM then refresh XPM data from ${spiels.APP.OP_SETTINGS}.`;
        },
      },
      OPS_IRD: {
        TITLE: "Login to MyIR",
        DEFAULT:
          "We need you to login to MyIR with your practice account, to simply recognise your authority – once logged in, IRD will return you back to this page.",
        get LOCKED() {
          return `We’re verifying your IRD Client List ID. This might take up to a few hours, depending on the amount of data. You can leave this page, we’ll email you when it’s ready.`;
        },
      },
      get TITLE_PRO() {
        return `Available in ${spiels.APP.NAME} Pro`;
      },
      get UPGRADE() {
        return `<a class='text-success' href='/operation-settings/plan'>Upgrade to Pro</a> or <a class='text-success' href='${spiels.LINK.PRICING}' target='_blank'>view feature comparison.</a>`;
      },
      get LETTER_CONTROL() {
        return `This is your letter control settings page. ${spiels.BANNER.UPGRADE}`;
      },
      OPS_EVENT_LOG: {
        CONTENT: "The event log will display email content for 30 days.",
      },
      OPS_OPERATION_STAFF: {
        PAGE_HEADING: "Practice users",
        CAN_ADD_ANOTHER_ALERT:
          "You can add, edit or remove other pratice users who manage tax letters.",
        NO_USERS_ADDED_ALERT:
          "No practice users added yet. Add a new user to get started.",
        ADD_PRACTICE_USER: "Add new practice user",
        MANAGE_PRACTICE_TEXT: "Add new practice users who manage tax letters.",
        ADMIN: "Administrator",
        OPERATION_SETTING_PAGE:
          "Giving administrator role will allow this user to access Operation settings page.",
        ADD_ANOTHER: "Add another",
        SEND_INVITE: "Send invite",
        RESEND_INVITE: "Resend invite",
      },
      OPS_PRACTICE_MANAGER: {
        get PAGE_HEADING() {
          return `XPM name and ${spiels.APP.TAX_OFFICE} name not exactly matching for this entity`;
        },
        SELECT_SAVE:
          "Select and save the correct XPM data for this entity. or match it manually.",
        get EMAIL() {
          return `You won’t be able to change XPM match after saving. If you have accidentally saved a wrong match, <a class="text-success" href='mailto:${spiels.SUPPORT.EMAIL}'>contact us</a>`;
        },
      },
      OPS_EMAIL: {
        TITLE: "Coming soon",
        UNSAVED_MODAL_BODY: {
          TITLE: "Leave this page without saving?",
          BODY: "Changes you made may not be saved.",
        },

        get BODY() {
          return `Manage all email templates going to clients and staff. <a class='text-success' href='${spiels.LINK.PRO_EMAIL}' target="_blank">${spiels.APP.LEARN_MORE}</a>`;
        },
        get ESSENTIALS() {
          return `Upgrade to ${spiels.APP.NAME} Pro to manage all email templates going to clients and staff. ${spiels.BANNER.UPGRADE}`;
        },
      },
      get OPS_STAFF() {
        return `Upgrade to ${spiels.APP.NAME} Pro to add or remove other users who manage tax letters. ${spiels.BANNER.UPGRADE}`;
      },
      OPS_MANUALLY_MATCH_XPM_ENTITY: {
        TITLE: "Manually match XPM entity",
        LIST: {
          ONE: "Go to XPM and open the entity’s clients page",
          TWO: "In the URL, you can find the entity’s XPM ID in 8 digits (https://xero.com/Client/<strong>12345678</strong>/Detail)",
          THREE: "Copy and paste the 8 digit XPM ID, and save. ",
        },
      },
      OPS_THINGS_TO_NOTE: {
        TITLE: "Things to note",
        LIST: {
          ONE: "We match entity information in IRD against entity information in XPM.",
          TWO: "Upload a client report spreadsheet for matching IRD entities to your XPM.",
          THREE: "If the matching fails, we will suggest the closest matches.",
          FOUR: "If none of the suggestions match, make sure the entity has an existing XPM record.",
          FIVE: "Tax Admin user accounts will be created for all the managers imported. To invite them to use the app, go to the Practice users tab under Operations settings.",
        },
      },
      OPS_ENTITY_NOT_MATCH: {
        get TITLE() {
          return `Some entity names did not match to ${spiels.APP.TAX_OFFICE}`;
        },
        CONTENT:
          "See our closest match suggestion. <a class='text-success' target='_blank' href='/tax-admin/client-control'>Go to clients page</a>",
      },
      BILLING_DETAILS: {
        INVOICE_RECIPIENT: {
          TITLE: "Invoice recipient",
        },
        BILLING_HISTORY: {
          TITLE: "Billing history",
        },
        TEXTS: {
          VIEW_INVOICE: "View invoice",
          INVOICE_RECIPIENT: "Invoice recipient",
        },
      },
      BILLING_PLAN: {
        YOUR_PLAN: {
          TITLE: "Your plan",
          get HELP() {
            return `To change your plan, please <a href='mailto:${spiels.SUPPORT.EMAIL}'>contact support</a>`;
          },
        },
        COMING_SOON: {
          TITLE: "Coming soon",
          BODY: "Tax Admin Pro AU is underway – even more and efficient ATO correspondences handling.",
        },
        TEXTS: {
          FEATURES_AND_PRICING: "Features & pricing",
          get AUTOMATED_TAX_LETTER_COLLECTION() {
            return `Automated ${spiels.APP.TAX_LETTER} collection`;
          },
          REAL_TIME_TAX_AGENCY_UPDATES: "Real time tax agency updates",
          get AUTOMATIC_LETTER_ARCHIVING_TOOL() {
            return `Automatic ${spiels.APP.TAX_LETTER} archiving tool`;
          },
          SET_LOGIC_RULES_FOR_PROCESSING_LETTERS:
            "Set logic rules for processing letters",
          VIEW_HISTORY_OF_ACTIONS_TAKEN: "View history of actions taken",
          CLIENT_AND_MANAGER_LIST_XPM_INTEGRATION:
            "Client and manager list XPM integration",
          COLLABORATIVE_MULTI_USER_ACCESS: "Collaborative multi-user access",
          CUSTOMISABLE_BRANDING_AND_EMAIL_TEMPLATES:
            "Customisable branding and email templates",
          get PER_IRD_NUMBER_PER_MONTH() {
            return `for unlimited number of ${spiels.APP.TAX_OFFICE_CLIENT}s`;
          },
          PER_IRD_NUMBER_PER_MONTH_CARD: "free plan for unlimited",
          get PER_IRD_NUMBER_PER_MONTH_PRO() {
            return `per ${spiels.APP.TAX_OFFICE_CLIENT} up to 2.5k ${spiels.APP.TAX_OFFICE_CLIENT}s`;
          },
          get ESS_CARD_TEXT() {
            return `Fundamental functionality of<br />${spiels.APP.TAX_LETTER} triage and filing`;
          },
          get PRO_CARD_TEXT_SUBTITLE() {
            return `per ${spiels.APP.TAX_OFFICE_CLIENT} per month`;
          },
          get PRO_CARD_TEXT_P1() {
            return `billed monthly for up to 2.5k ${spiels.APP.TAX_OFFICE_CLIENT}s`;
          },
          PRO_CARD_TEXT_P2: i18n.global.t("sortLetterCardText"),
          get UPGRADE_PRO_DIALOG() {
            return `
            <ul>
              <li>Pricing starts at ${spiels.APP.CURRENCY_CODE} ${spiels.APP.PRODUCTS.PRO.PRICE} ${spiels.BANNER.BILLING_PLAN.TEXTS.PER_IRD_NUMBER_PER_MONTH_PRO}.</li>
              <li>Letters will be paused until XPM connection is completed, and the ${spiels.APP.TAX_LETTER} action is activated.</li>
              <li>You will need to contact support if you wish to change your plan to Essentials.</li>
              <li>Our support team will be in touch once the upgrade is complete.</li>
            </ul>
          `;
          },
          UPGRADE_PRO_BANNER: {
            TITLE: "We're upgrading you to Pro",
            BODY: "Our support team will be in touch once the upgrade is complete.",
          },
        },
      },
    },
    ERROR: {
      XPM: "XPM connection failed: <a href='/operation-settings/xpm'>check XPM connection</a>",
      get AXIOS() {
        return (
          "Sorry, an error occurred. Please try again later or contact " +
          spiels.SUPPORT.EMAIL
        );
      },
      FORBIDDEN:
        "Access Denied You are not allowed to access this page at this time.",
      UNAUTHORIZED: "Unathorized access to this page.",
      SESSION_EXPIRED: "Your session has expired. Please log in again.",
    },
    SUCCESS: {
      MESSAGE: "Notification email sent",
    },
    BTN: {
      CANCEL: "Cancel",
      NEXT: "Next",
      CONTINUE: "Continue",
      SAVE: "Save",
    },
  };
  return Object.freeze(spiels);
};

export const setupI18n = async (locale) => {
  const i18n = createI18n({
    locale: locale,
    fallbackLocale: "en-NZ",
    messages: {
      "en-NZ": await loadLocaleMessages("en-NZ"),
      "en-AU": await loadLocaleMessages("en-AU"),
    },
  });
  return i18n;
};

async function loadLocaleMessages(locale) {
  const messages = await import(`@/locales/${locale}.json`);
  return messages.default;
}

export const i18nStub = {
  global: {
    t: (key) => `i18n_${key}`,
  },
};
