<template>
  <div :class="navMenuClasses" v-if="store.getItem('firstName')">
    <div :class="leftColumnClasses">
      <img v-bind:src="navIcon" />
      <template v-if="!hideNav">
        <router-link
          v-for="(item, index) in navMenus"
          class="text"
          :to="item.path"
          :key="index"
          >{{ item.name }}
        </router-link>
      </template>
    </div>
    <div :class="rightColumnClasses">
      <span
        v-if="isSysAdmin"
        style="font-weight: normal"
        class="user-practice"
        :class="isSandbox ? 'sandbox' : 'live'"
      >
        <span class="me-1"
          >{{ store.decodedPartner.practiceName }}
          {{ store.getItem("partnerId") }}</span
        >
        <strong v-if="!isSandbox" class="me-2">LIVE</strong>
        <button @click="practiceJson" class="btn"><CodeJsonIcon /></button>
      </span>

      <NDropdown
        v-if="!hideAccount"
        :options="options"
        placement="bottom-start"
        trigger="click"
        @select="handleSelect"
      >
        <button :class="userAccount">
          <component :is="'account'" />
          <span class="me-1">{{ store.getItem("firstName") }}</span>
        </button>
      </NDropdown>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, inject } from "vue";
import { NDropdown } from "naive-ui";
import { logout } from "@/services/login.js";
import navIcon from "@/assets/navIcon.svg";
import AccountIcon from "vue-material-design-icons/Account.vue";
import router from "@/router/index.js";
import { sendCustomEvent } from "@/services/userApi.js";
import { useClientStore } from "@/stores/clientStore";
import { datadogRum } from "@datadog/browser-rum";
import { isSandbox, isSysAdmin, jsonLink } from "@/services/admin.js";
import CodeJsonIcon from "vue-material-design-icons/CodeJson.vue";

const options = ref([
  {
    label: "Account",
    key: "/account",
  },
  {
    label: "Support",
    key: "/support",
  },
  {
    label: "Logout",
    key: "/logout",
  },
]);

export default defineComponent({
  components: {
    account: AccountIcon,
    NDropdown,
    CodeJsonIcon,
  },
  data() {
    return {
      navIcon,
    };
  },
  setup() {
    const spiels = inject("spiels");
    const store = useClientStore();
    const isPro = store.getItem("firstName")
      ? store.getItem("plan") === "product_pro"
      : false;
    const hideNav = computed(() => router.currentRoute.value.meta?.hideNav);
    const hideAccount = computed(
      () => router.currentRoute.value.meta?.hideAccount
    );

    const supportLink = isPro ? spiels.LINK.PRO : spiels.LINK.ESSENTIALS;

    const practiceJson = () => jsonLink(store.decodedPartner);

    return {
      spiels,
      store,
      options,
      isSandbox,
      isSysAdmin: computed(() => isSysAdmin(store)),
      practiceJson,
      hideNav,
      hideAccount,
      navMenus: computed(() =>
        router.options.routes
          .map((item) => {
            if (item.name.value) {
              return { ...item, name: item.name.value };
            } else {
              return item;
            }
          })
          .filter(
            (item) =>
              item.meta.showInNav && !item.meta.hideTab?.(store.getItem("role"))
          )
      ),
      navMenuClasses: computed(() => ({
        "nav-menu": true,
        "nav-menu--bordered": !hideNav.value || !hideAccount.value,
      })),
      userAccount: computed(() => ({
        "user-account": true,
      })),
      leftColumnClasses: computed(() => ({
        "left-column": true,
      })),
      rightColumnClasses: computed(() => ({
        "right-column": true,
      })),
      handleSelect: async (key) => {
        if (key === "/logout") {
          sendCustomEvent(
            JSON.stringify({ eventType: "TAA - User logged out" })
          );
          await logout().finally(() => localStorage.clear());
          datadogRum.clearUser();
          window.location = "/";
        } else {
          if (key === "/support") {
            window.open(supportLink, "_blank");
          } else router.push(key);
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
@import "./NavMenu.scss";
</style>
